<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 shadow-none mx-auto pt-2 rounded">
        <div class="card shadow">
          <div class="card-body text-center">
            <i
              class="fa fa-sticky-note-o fa-2"
              aria-hidden="true"
            />
            <div class="signin-label mb-4">
              Generate Bill
            </div>
            <p v-if="form.user_id">
              Employee ID: {{ $route.params.id }} <br>
              Customer Name: {{ userName }}
            </p>
            <b-overlay
              :show="isBusy"
              rounded="sm"
              spinner-type="grow"
              spinner-variant="primary"
            >  
              <b-row v-if="showMeters"> 
                <b-col
                  sm="12"
                  class="mx-auto table-responsive-sm my-3"
                >
                  <b-form @submit.stop.prevent="onSubmit">
                    <b-form-group 
                      v-for="meter in form.meters" 
                      id="fieldset-horizontal"
                      :key="meter.name"
                      :state="validateState('meters')"
                      label-cols="3"
                      :description="`Previous Reading: ${meter.prev_read}. Units: ${(meter.reading - meter.prev_read)}`"
                      :label="meter.name"
                      label-for="input-horizontal"
                    >
                      <b-form-input
                        v-model="meter.reading"
                        type="number"
                        aria-describedby="reading-live-feedback"
                      />
                      <b-form-select
                        v-model="meter.status"
                        :options="options"
                        size="sm"
                        class="mt-3"
                      />
                    </b-form-group>

                    <div class="text-center">
                      <b-button
                        pill
                        type="submit"
                        variant="success"
                        size="md"
                        class="mt-3"
                        :disabled="submitted"
                      >
                        <i class="fa fa-print" /> Generate
                      </b-button>
                    </div>
                  </b-form>
                </b-col>      
              </b-row>
              <b-row v-else>
                <b-button
                  pill
                  type="submit"
                  variant="warning"
                  size="sm"
                  class="mx-auto mt-3"
                  to="/biller"
                >
                  <i class="fa fa-arrow-circle-left" />  Return Home
                </b-button>
              </b-row> 
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/mixins/helper';
import { apiService } from "@/services";
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate"; 
import { required } from "vuelidate/lib/validators";
import router from '@/router';

export default {
  name: 'UserMeters',
  mixins: [validationMixin, helper],
  data() {
    return {
      isBusy: true,
      showMeters: false,
      form: {
        meters: [ { 
          id: '', 
          name: 'M1', 
          prev_read: '0', 
          reading: '0', 
          status: 0 
        } ],
      },
      options: [
        { value: 0, text: 'Faulty' },
        { value: 1, text: 'Active' },
      ]
    }
  },
  validations: {
    submitted: false,
    userName: '',
    form: {
      user_id: '',
      meters: {
        required,
        $each: {
          name: {
            required
          }
        }
      }
    }
  },
  computed: {
    ...mapState('tariff', ['formError'])
  },
  mounted(){
    this.getMeters(this.$route.params.id); // Push the Emp Num here!
  },
  methods: {
    ...mapActions('tariff', ['generateBill'] ),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getMeters(id){
      apiService.postAll(`/usermeters`, {
        emp_num: id
      }).then((response) => {
        this.isBusy = false;
        this.userName = response.data.user.name;
        this.form.user_id = response.data.user.id;
        this.form.meters = response.data.meters;
        this.showMeters = this.form.meters.length > 0 ? true : false;
      }).catch(error => {
        this.isBusy = false;
        this.showMeters = false;
        this.showError(error);
      });  
    },
    onSubmit(){
      this.submitted=true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }else{
        this.submitted = true;
        apiService.postAll(`/tariffs`, this.form)
          .then((response) => {
            if(response.status == 201){
              router.replace('/tariffs/'+response.data.id);
            }
          }).catch(error => this.showError(error));
      }
    },
  },
};
</script>

<style scoped>
	.signin-label{ font-size: 1.2rem; color: #656262; }
	.remember-me-label{ font-size: 0.8rem; vertical-align: middle; color: #656262; }
	.forgot-password{ font-size: 0.8rem; color: #00b7c2; }
	.sign-up-label{ font-size: 0.8rem; color: #0f4c75; }
	.input-label{ font-size: 14px; color: #656262; }
	.fa-2{ font-size: 6rem; }
</style>